var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth" }, [
    _c("div", { staticClass: "auth-wrapper" }, [
      _c("div", { staticClass: "card mw468" }, [
        _c(
          "div",
          { staticClass: "card__content pt-60 pb-52 px-56" },
          [
            _c("img", {
              staticClass: "d-block w-100 mw170 mx-auto mb-80 mb-mb-60",
              attrs: { src: require("@/assets/svg/logo_full.svg") }
            }),
            _c(
              "b-form-group",
              {
                staticClass: "mb-40",
                attrs: {
                  id: "email-field",
                  label: "New Password",
                  "label-for": "password"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "email",
                    type: "password",
                    placeholder: "Enter New Password",
                    trim: ""
                  },
                  model: {
                    value: _vm.form.password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "password", $$v)
                    },
                    expression: "form.password"
                  }
                })
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                staticClass: "mb-40",
                attrs: {
                  id: "email-field",
                  label: "Confirm New Password",
                  "label-for": "confirm_password"
                }
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: "confirm_password",
                    type: "password",
                    placeholder: "Confirm New Password",
                    trim: ""
                  },
                  model: {
                    value: _vm.form.confirmPassword,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "confirmPassword", $$v)
                    },
                    expression: "form.confirmPassword"
                  }
                })
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass: "w-100 mb-40",
                attrs: { variant: "primary", size: "lg" },
                on: { click: _vm.resetPassword }
              },
              [_vm._v(" Submit ")]
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center" },
              [
                _c("b-link", { attrs: { to: "/login" } }, [
                  _vm._v(" Retry logging in? ")
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "bottom-bar d-flex justify-content-between mx-40" },
        [
          _c(
            "span",
            { staticClass: "fs12 lh20 text-gray-400 mb-16 mb-md-32" },
            [_vm._v(" © 2021 Shyplite — All Rights Reserved. ")]
          ),
          _c("ul", { staticClass: "lsnone p-0 mb-16 mb-md-32" }, [
            _c(
              "li",
              [
                _c(
                  "b-link",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/tou"
                    }
                  },
                  [_vm._v("Terms")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/privacy"
                    }
                  },
                  [_vm._v("Privacy")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/contact/"
                    }
                  },
                  [_vm._v("Contact Us")]
                )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }