<template>
  <div class="auth">
    <div class="auth-wrapper">
      <div class="card mw468">
        <b-form @submit.prevent="validateMail" class="card__content pt-60 pb-52 px-56">
          <img
            src="@/assets/svg/logo_full.svg"
            class="d-block w-100 mw170 mx-auto mb-80 mb-mb-60"
          />
          <b-form-group
            id="email-field"
            label="Email"
            label-for="email"
            class="mb-40"
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="state"
          >
            <b-form-input
              id="email"
              v-model="form.username"
              placeholder="Your email ID"
              trim
            ></b-form-input>
          </b-form-group>

          <b-button
            variant="primary"
            :disabled="submitting"
            type="submit"
            size="lg"
            class="w-100 mb-40"
          >
            Submit <b-spinner v-if="submitting" class="ml-10"></b-spinner>
          </b-button>
          <div class="d-flex flex-column align-items-center">
            <!-- <span class="fs12 lh20 text-gray-800 mb-16">
              New here?
              <b-link to="/sign-up">Sign Up</b-link>
            </span> -->
            <b-link href="/login"> Retry logging in? </b-link>
          </div>
        </b-form>
      </div>
      <div class="mob_footer bottom-bar d-flex justify-content-between mx-40">
        <span class="fs12 lh20 text-gray-400 mb-16 mb-md-32">
          © 2021 Shyplite — All Rights Reserved.
        </span>
        <ul class="lsnone p-0 mb-16 mb-md-32">
          <li>
            <b-link target="_blank" href="https://shyplite.com/tou">Terms</b-link>
            <b-link class="ml-20" target="_blank" href="https://shyplite.com/privacy">Privacy</b-link>
            <b-link class="ml-20" target="_blank" href="https://shyplite.com/contact/">Contact Us</b-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  title: "Shyplite - Forgot Password?",
  data() {
    return {
      form: { username: "" },
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      state: null,
      submitting: false,
    };
  },
  computed: {
    invalidFeedback() {
      return "Invalid Email Format!! Please Enter again";
    },
    validFeedback() {
      return "";
    },
  },
  mounted() {
    document.querySelectorAll('a').forEach(i => {
        let href = i.getAttribute('href');
        if(window.location.search) {
            i.setAttribute('href', `${href}${window.location.search}`);
        }
    })
  },
  methods: {
    async validateMail() {
      if (this.reg.test(this.form.username)) {
        this.state = true;
        this.submitting = true
        this.form.app = 'slv'
        try {
          const res = await this.axios.post("/forgotpassword", this.form);
          if (res.data.success) {
            this.form.username = ''
            this.popToast("booked", "Success!", res.data.message);
          } else {
            this.popToast("failed", "Failed!", "Enter an Email Id registered with us!! Please Try again");
          }
        } catch (error) {
            this.popToast("failed", "Failed!", res.data.message);
        }
        this.submitting = false
      } else {
        this.state = false;
      }
    },
  },
};
</script>
