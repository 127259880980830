var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth" }, [
    _c("div", { staticClass: "auth-wrapper" }, [
      _c(
        "div",
        { staticClass: "card mw468" },
        [
          _c(
            "b-form",
            {
              staticClass: "card__content pt-60 pb-52 px-56",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateMail($event)
                }
              }
            },
            [
              _c("img", {
                staticClass: "d-block w-100 mw170 mx-auto mb-80 mb-mb-60",
                attrs: { src: require("@/assets/svg/logo_full.svg") }
              }),
              _c(
                "b-form-group",
                {
                  staticClass: "mb-40",
                  attrs: {
                    id: "email-field",
                    label: "Email",
                    "label-for": "email",
                    "invalid-feedback": _vm.invalidFeedback,
                    "valid-feedback": _vm.validFeedback,
                    state: _vm.state
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      placeholder: "Your email ID",
                      trim: ""
                    },
                    model: {
                      value: _vm.form.username,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "w-100 mb-40",
                  attrs: {
                    variant: "primary",
                    disabled: _vm.submitting,
                    type: "submit",
                    size: "lg"
                  }
                },
                [
                  _vm._v(" Submit "),
                  _vm.submitting
                    ? _c("b-spinner", { staticClass: "ml-10" })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column align-items-center" },
                [
                  _c("b-link", { attrs: { href: "/login" } }, [
                    _vm._v(" Retry logging in? ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "mob_footer bottom-bar d-flex justify-content-between mx-40"
        },
        [
          _c(
            "span",
            { staticClass: "fs12 lh20 text-gray-400 mb-16 mb-md-32" },
            [_vm._v(" © 2021 Shyplite — All Rights Reserved. ")]
          ),
          _c("ul", { staticClass: "lsnone p-0 mb-16 mb-md-32" }, [
            _c(
              "li",
              [
                _c(
                  "b-link",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/tou"
                    }
                  },
                  [_vm._v("Terms")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/privacy"
                    }
                  },
                  [_vm._v("Privacy")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/contact/"
                    }
                  },
                  [_vm._v("Contact Us")]
                )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }