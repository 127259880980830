<template>
  <div class="auth">
    <div class="auth-wrapper">
      <div class="card mw468">
        <div class="card__content pt-60 pb-52 px-56">
          <img
            src="@/assets/svg/logo_full.svg"
            class="d-block w-100 mw170 mx-auto mb-80 mb-mb-60"
          />
          <b-form-group
            id="email-field"
            label="New Password"
            label-for="password"
            class="mb-40"
          >
            <b-form-input
              id="email"
              type="password"
              v-model="form.password"
              placeholder="Enter New Password"
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="email-field"
            label="Confirm New Password"
            label-for="confirm_password"
            class="mb-40"
          >
            <b-form-input
              id="confirm_password"
              type="password"
              v-model="form.confirmPassword"
              placeholder="Confirm New Password"
              trim
            ></b-form-input>
          </b-form-group>

          <b-button
            @click="resetPassword"
            variant="primary"
            size="lg"
            class="w-100 mb-40"
          >
            Submit
          </b-button>
          <div class="d-flex flex-column align-items-center">
            <!-- <span class="fs12 lh20 text-gray-800 mb-16">
              New here?
              <b-link to="/sign-up">Sign Up</b-link>
            </span>
 -->            <b-link to="/login"> Retry logging in? </b-link>
          </div>
        </div>
      </div>
      <div class="bottom-bar d-flex justify-content-between mx-40">
        <span class="fs12 lh20 text-gray-400 mb-16 mb-md-32">
          © 2021 Shyplite — All Rights Reserved.
        </span>
        <ul class="lsnone p-0 mb-16 mb-md-32">
          <li>
            <b-link target="_blank" href="https://shyplite.com/tou">Terms</b-link>
            <b-link class="ml-20" target="_blank" href="https://shyplite.com/privacy">Privacy</b-link>
            <b-link class="ml-20" target="_blank" href="https://shyplite.com/contact/">Contact Us</b-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  title: "Shyplite - Reset Password",
  data() {
    return {
      form: { password: "", confirmPassword: "", token:"", emailID:"" },
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      state: null,
    };
  },
  computed: {},
  mounted() {
    this.form.token = this.$route.query.token
    this.form.emailID = this.$route.query.emailID
    console.log(this.$route.query.token)
  },
  methods: {
    async resetPassword() {
      try {
        if (this.form.password != this.form.confirmPassword) {
          this.popToast("failed", "Failed!", "Passwords didn't match!! Please Enter again");
        } else {
          const res = await this.axios.post("/resetpassword", this.form);
          console.log(res);
          if (res.data.success) {
            this.popToast("booked", "Success!", "Password Reset Successfully!! Please Login with new password");
            setTimeout(() => {
              this.$router.push("/login");
            }, 2000);
          } else {
            this.popToast("failed", "Failed!", res.data.error);
          }
        }
      } catch (error) {}
    },
    
  },
};
</script>
